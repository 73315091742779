import React from "react";

export default ({ currentUser }) => {
  return (
    <>
      <div>Main Page</div>
      {currentUser && (
        <div>
          Welcome, {currentUser.givenName} {currentUser.familyName}
        </div>
      )}
    </>
  );
};



import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";

import campaignsObj, {
  allCampaignLocations,
} from "../services/campaignsDefinitions";

const useCampaign = ({ defaultCampaign = {} }) => {
  const [campaign, setCampaign] = useState(defaultCampaign);
  const { campaignCode, subpage } = useParams();
  const location = useLocation();
  useEffect(() => {
    setCampaign(
      campaignsObj[
        campaignCode ||
          (allCampaignLocations.find((l) => l === location.pathname) && "all")
      ],
    );
  }, [campaignCode]);

  return {
    campaign,
    subpage,
    campaignCode,
    setCampaign,
  };
};

export default useCampaign;

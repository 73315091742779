import appAxios from "../services/axios";

export const loginUserToken = ({ tokenLogin }) => {
  return appAxios
    .post("/api/user/login", { tokenLogin: tokenLogin })
    .then((resp) => {
      return resp.data;
    });
};

export const getCurrentUser = () => {
  return appAxios.get("/api/user/current").then((resp) => {
    return resp.data;
  });
};

export const logoutUserRequest = () => {
  return appAxios.post("/api/user/logout").then((resp) => {
    return resp.data;
  });
};

import "./nav.css";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import { MoreMenu, MoreMenuItem } from "../more-menu/MoreMenu";

const Nav = ({ logoutUser }) => {
  const { currentUser } = useContext(AppContext);

  const navigate = useNavigate();

  return (
    <nav className="navbar navbar-expand-lg navbar-light transparent app-primary-nav noselect" >
      <a
        className="navbar-brand clickable"
        onClick={(e) => {
          e.preventDefault();
          navigate("/");
        }}
      >
        <img className="logo" src={"/logo512.png"} alt="Sajor Table Top Logo" />
        Sajor Tabletop
      </a>
      <ul className="navbar-nav mr-auto">
        <li className="nav-item">
          <a
            className="nav-link clickable"
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
          >
            Home
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link clickable"
            onClick={(e) => {
              e.preventDefault();
              navigate("/campaigns/vestillion/homepage");
            }}
          >
            The Vestillon Brand
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link clickable"
            onClick={(e) => {
              e.preventDefault();
              navigate("/campaigns/corral/homepage");
            }}
          >
            The Corral
          </a>
        </li>
        {currentUser.isAuth && (
          <li className="nav-item dropdown">
            <MoreMenu
              moreMenuTitle={`${currentUser?.givenName} ${currentUser?.familyName}`}
            >
              <MoreMenuItem key="navbar-profile" disabled={true}>
                Profile (Coming Soon)
              </MoreMenuItem>
              <div className="dropdown-divider"></div>
              <MoreMenuItem key="navbar-logout" onClick={logoutUser}>
                Logout
              </MoreMenuItem>
            </MoreMenu>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Nav;

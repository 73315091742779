import React, { useContext } from "react";
import { AppContext } from "../App";
import Backpannel from "./Backpannel";

export default ({ Component, ...props }) => {
  const { currentUser } = useContext(AppContext);
  return <Backpannel>
      <Component currentUser={currentUser} {...props} />
    </Backpannel>;
};

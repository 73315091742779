import { useEffect, useState } from "react";
import {
  loginUserToken,
  getCurrentUser,
  logoutUserRequest,
} from "../requests/user";

const useUser = ({ defaultUser = {} }) => {
  const [currentUser, setCurrentUser] = useState(defaultUser);

  const loginUser = ({ credential }) => {
    loginUserToken({ tokenLogin: credential }).then((userObject) => {
      setCurrentUser({
        ...userObject,
        credentialToken: credential,
        isAuth: true,
      });
    });
  };

  const logoutUser = () => {
    logoutUserRequest().then(setCurrentUser({ isAuth: false }));
  };

  useEffect(() => {
    getCurrentUser().then((userObject) => {
      if (userObject && userObject.email) {
        setCurrentUser({ ...userObject, isAuth: true });
      }
    });
  }, []);

  return {
    currentUser,
    loginUser,
    logoutUser,
  };
};

export default useUser;

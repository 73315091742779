const campaignsObj = {
  vestillion: {
    name: "The Vestillion Brand",
    code: "vestillion",
  },
  corral: {
    name: "The Corral",
    code: "corral",
  },
  all: {
    name: "All Campaigns",
    code: "all",
  },
};

export const allCampaignLocations = ["/login", "/"];

export default campaignsObj;

import "./campaign.css";

import React from "react";
import useCampaign from "../hooks/campaign";
import VestillionHomePage from "./vestillion/HomePage";
import CorralHomePage from "./corral/HomePage";
import NotFound from "../components/not-found/NotFound";

export default ({ props }) => {
  const { campaign, subpage } = useCampaign({});

  return (
    <>
      {campaign?.code === "vestillion" && <VestillionHomePage {...props} subpage={subpage} />}
      {campaign?.code === "corral" && <CorralHomePage {...props} subpage={subpage} />}
      {campaign?.code !== "vestillion" && campaign?.code !== "corral" && <NotFound/>}
    </>
  );
};

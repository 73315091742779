import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";

import { faQuestionCircle } from "../../util/FontAwesomeIcons";

const MoreMenuItem = (props) => (
  <div
    className={`dropdown-item ${props.disabled ? "disabled" : ""}`}
    onClick={(e) => {
      e.stopPropagation();
      !props.disabled && props.onClick(e);
    }}
  >
    {props.children}
    {props.toolTip && props.toolTipText ? (
      <>
        <span
          data-tooltip-id={props["data-tooltip-id"]}
          data-tooltip-content={props.toolTipText}
        >
          <FontAwesomeIcon
            icon={faQuestionCircle}
            size="lg"
            style={{ marginLeft: "10px" }}
          />
        </span>
        <Tooltip id={props["data-tooltip-id"]} />
      </>
    ) : (
      ""
    )}
  </div>
);

const MoreMenu = (props) => {
  const [showMoreMenu, setShowMoreMenu] = useState(false);

  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      e.stopPropagation();
      if (
        showMoreMenu &&
        wrapperRef.current &&
        !wrapperRef.current.contains(e.target)
      ) {
        setShowMoreMenu(false);
      }
    };

    if (showMoreMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMoreMenu]);

  return (
    <div
      style={{
        ...(props.style ? props.style : {}),
        position: "relative",
      }}
      ref={wrapperRef}
    >
      <Tooltip />
      <a
        className={`nav-link dropdown-toggle`}
        role="button"
        onClick={(e) =>
          e.stopPropagation() || setShowMoreMenu((previous) => !previous)
        }
      >
        {props.moreMenuTitle}
      </a>
      {showMoreMenu && (
        <div
          className={`dropdown-menu${showMoreMenu ? " show" : ""}`}
          onClick={(e) => e.stopPropagation()}
          style={{
            ...(showMoreMenu ? { display: "block" } : {}),
            ...(props.dropRight ? { left: "-20px" } : {}),
            cursor: "auto",
          }}
        >
          {props.children}
        </div>
      )}
    </div>
  );
};

export default MoreMenu;
export { MoreMenu, MoreMenuItem };

import React from "react";

export default ({ currentUser }) => {
  return (
    <>
      <div>The Vestillion Home Page</div>
      <div>
        Welcome, {currentUser?.givenName} {currentUser?.familyName}
      </div>
    </>
  );
};

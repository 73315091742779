import React from "react";
import { useNavigate } from "react-router";
import "./subpagelinkcard.css";

export default ({ linkToPath, cardText, cardTitle, disabled }) => {
  const navigate = useNavigate();
  return (
    <div
      className={`card ${
        disabled ? "sublink-card-disabled" : "sublink-card clickable"
      }`}
      onClick={disabled ? () => {} : () => navigate(linkToPath)}
    >
      <div className="card-body">
        <h5 className="card-title">{cardTitle}</h5>
        <p className="card-text">{cardText}</p>
      </div>
    </div>
  );
};

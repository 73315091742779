import React from 'react'
import useCampaign from '../hooks/campaign'
import NotFoundParallax from './not-found/NotFoundParallax';


export default function Backpannel({ mode, children }) {
    const { campaign } = useCampaign({});

    const backgroundImageObj = {
        corral: [
          "/background-images/central-galvion.png",
          "/background-images/west-galvion.png",
        ],
        vestillion: [
          "/background-images/nelic-embrace.png",
          "/background-images/attris.png",
        ],
      };


    const chooseBackgroundImage = ({ campaign }) => {
        if (campaign in backgroundImageObj) {
            return backgroundImageObj[campaign][
            Math.floor(Math.random() * backgroundImageObj[campaign].length)
            ];
        } else if (campaign === "all") {
            return Object.values(backgroundImageObj).flat()[
            Math.floor(
                Math.random() * Object.values(backgroundImageObj).flat().length,
            )
            ];
        } else {
            return null;
        }
    };

    const getStyleObject = () => {
        const useUrl = chooseBackgroundImage(
            { campaign: campaign?.code },
        )

        if(useUrl) {
            return {
                position: "relative",
                background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), top / contain no-repeat url("${useUrl}")`,
            }
        }
    }

    return <div
        className="app-body"
        style={getStyleObject()}>
        {mode === '404' && <NotFoundParallax/>}
        {children}
    </div>;
}
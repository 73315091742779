import { useState, useMemo } from "react";

const useMousePosition = () => {
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);

  const bind = useMemo(
    () => ({
      onMouseMove: (event) => {
        setMouseX(event.nativeEvent.offsetX);
        setMouseY(event.nativeEvent.offsetY);
      }
    }),
    []
  );

  return [mouseX, mouseY, bind];
};

export default useMousePosition;
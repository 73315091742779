import React from 'react'
import useMousePosition from '../../hooks/mousePosition'
import useWindowDimensions from '../../hooks/windowDimensions'
import { ReactComponent as NotFoundLayer0 } from './svgs/404sands0.svg'
import { ReactComponent as NotFoundLayer1 } from './svgs/404sands1.svg'
import { ReactComponent as NotFoundLayer2 } from './svgs/404sands2.svg'
import { ReactComponent as NotFoundLayer3 } from './svgs/404sands3.svg'
import { ReactComponent as NotFoundLayer4 } from './svgs/404sands4.svg'

const NotFoundParallax = () => {
    const [mouseX, mouseY, bind] = useMousePosition();
    const {windowHeight, windowWidth} = useWindowDimensions();  // eslint-disable-line

    const allStyleSvg = {minHeight:"100vh", minWidth:"100vw"};
    const allStyleWrapper = {position:"fixed", "top":"0", left:"0", "width": "100%", "heigh": "100%"}

    //console.log(`${x}, ${y}`);

    const parallax = (factorHorizontal, factorVertical, magnitude = 1, log = false) => {

        // avoid divide by zero errors
        const adjustedWindowWidth = !windowWidth || windowWidth <= 0 ? 1 : windowWidth;
        const adjustedWindowHeight = !windowHeight || windowHeight <= 0 ? 1 : windowHeight;

        // Find distance from mid and adjust by factor.
        const windowMidWidth = adjustedWindowWidth / 2;
        const windowMidHeight = adjustedWindowHeight / 2;

        const mouseDistanceFromMidX = windowMidWidth - mouseX;
        const mouseDistanceFromMidY = windowMidHeight - mouseY;

        const parallaxShiftX = (mouseDistanceFromMidX) * factorHorizontal * magnitude;
        const parallaxShiftY = (mouseDistanceFromMidY) * factorVertical * magnitude;


        log && console.log(`(${parallaxShiftX}px, ${parallaxShiftY}px)`);
        return `scale(1.2) translate(${parallaxShiftX}px, ${parallaxShiftY}px)`;

    }

    return <div {...bind}>
        <div style={allStyleWrapper}><NotFoundLayer0 style={{...allStyleSvg}} height="100%" width="100%" viewBox="0 0 1400 700" preserveAspectRatio="xMaxYMax slice"/></div>
        <div style={allStyleWrapper}><NotFoundLayer1 style={{...allStyleSvg, transform: parallax(0.009, 0.00, 1.4)}} height="100%" width="100%" viewBox="0 0 1400 700" preserveAspectRatio="xMaxYMax slice"/></div>
        <div style={allStyleWrapper}><NotFoundLayer2 style={{...allStyleSvg, transform: parallax(0.009, 0.01, 1.6)}} height="100%" width="100%" viewBox="0 0 1400 700" preserveAspectRatio="xMaxYMax slice"/></div>
        <div style={allStyleWrapper}><NotFoundLayer3 style={{...allStyleSvg, transform: parallax(0.015, 0.01, 4.8)}} height="100%" width="100%" viewBox="0 0 1400 700" preserveAspectRatio="xMaxYMax slice"/></div>
        <div style={allStyleWrapper}><NotFoundLayer4 style={{...allStyleSvg, transform: parallax(0.009, 0.01, 7.0)}} height="100%" width="100%" viewBox="0 0 1400 700" preserveAspectRatio="xMaxYMax slice"/></div>
    </div>
};

export default NotFoundParallax;
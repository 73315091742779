import React from "react";
import SubPageLinkCard from "./SubPageLinkCard";

export default ({ currentUser, campaign }) => {
  console.log(currentUser);
  const subPages = [
    {
      cardId: 0,
      cardTitle: "Skill Tree",
      cardText: "",
      linkToPath: `/campaigns/${campaign.code}/skilltree`,
      cardImage: null,
    },
    {
      cardId: 1,
      cardTitle: "Celestial Language",
      cardText: "",
      linkToPath: "",
      disabled: true,
      cardImage: null,
    },
    {
      cardId: 2,
      cardTitle: "Calendar",
      cardText: "",
      linkToPath: "",
      disabled: true,
      cardImage: null,
    },
  ];

  return (
    <div className="container">
      <div className="row">
        <div className="card campaign-card">
          <div className="card-header">
            <h1 className="card-title text-center">The Corral</h1>
          </div>
          <div className="card-body">
            <div className="card-subtitle text-muted">
              <div className="row">
                <div className="col-md-4">
                  <p className="text-left font-italic">
                    Ven On It Del Fintra Sahs Hel
                  </p>
                </div>
                <div className="col-md-4"></div>
                <div className="col-md-4">
                  <p className="text-right font-italic">
                    Eith On It Del Fintra Kor Ork Sahs
                  </p>
                </div>
              </div>
            </div>
            <div></div>
            <div className="row">
              {subPages.map((sp) => (
                <div key={`subpage-card-${sp.cardId}`} className="col-md-4">
                  <SubPageLinkCard {...sp} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

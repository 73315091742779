import React, { useEffect } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import "./login.css";

export default ({ currentUser, loginUserCallback }) => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (currentUser.isAuth) {
      navigate(queryString.parse(location.search)?.referer || "/");
    }
  }, [currentUser.isAuth]);

  return (
    <div className="container">
      <div className="row">
        <div className="card login-card text-center">
          <div className="card-header">Welcome Adventurer</div>
          <div className="card-body">
            <h5 className="card-title">Login to your Account</h5>
            <p className="card-text">
              We only support google auth at the moment
            </p>
            <div className="row center">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    loginUserCallback(credentialResponse);
                  }}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                />
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import { Link } from "react-router-dom";
import "./notfound.css";
import Backpannel from '../Backpannel';

export default function NotFound() {
  return (<Backpannel mode="404">
    <div className="container">
      <div className="row">
        <div className="card not-found-card text-center">
          <div className="card-header">You seem to be lost, adventurer...</div>
          <div className="card-body">
            <h5 className="card-title">Let me help you find your way home</h5>
            <p className="card-text">Maybe one of these pages will help</p>
            <div className="row center">
              <div className="col-md-4 link-opacity-75">
                <Link to="/">Home</Link>
              </div>
              <div className="col-md-4 link-opacity-75">
                <Link to="/campaigns/corral">The Corral</Link>
              </div>
              <div className="col-md-4 link-opacity-75">
                <Link to="/campaigns/vestillion">The Vestillion Brand</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Backpannel>
  );
}

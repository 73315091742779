import "./app.css";
import React, { useContext, createContext } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Outlet,
  Navigate,
  useLocation,
} from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import useUser from "./hooks/user";
import appAxios from "./services/axios";
import Page from "./components/Page";
import Login from "./components/login/Login";
import Main from "./components/dashboard/Main";
import Nav from "./components/nav/Nav";
import NotFound from "./components/not-found/NotFound";
import Campaign from "./campaigns/Campaign";

export const AppContext = createContext();

const AppWrapper = ({ logoutUser }) => {
  return (
    <GoogleOAuthProvider clientId="405075599114-d4rtlc1d2jds1tovtsbscaguomg73aq5.apps.googleusercontent.com">
      <div className="app">
        <header className="app-header">
          <Nav logoutUser={logoutUser} />
        </header>
        <div className="app-body">
          <Outlet />
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

const AuthenticatedRoute = () => {
  const { currentUser } = useContext(AppContext);
  const location = useLocation();

  // check if user is authenticated
  return currentUser.isAuth ? (
    <Outlet />
  ) : (
    <Navigate to={`/login?referer=${location.pathname}`} />
  );
};

const App = () => {
  const { currentUser, loginUser, logoutUser } = useUser({});
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<AppWrapper logoutUser={logoutUser} />}>
        {/* Anything under a Route that has an AuthenticatedRoute element requires auth on all children routes */}
        <Route element={<AuthenticatedRoute />}>
          <Route path="/" element={<Page Component={Main} />} />
          <Route
            path="/campaigns/:campaignCode/:subpage"
            element={<Page Component={Campaign} />}
          />
        </Route>
        <Route
          path="/login"
          element={
            <Page Component={Login} props={{ loginUserCallback: loginUser }} />
          }
        />
        <Route path="*" element={<NotFound />} />
      </Route>,
    ),
  );
  return (
    <AppContext.Provider value={{ currentUser, appAxios }}>
      <RouterProvider router={router} />
    </AppContext.Provider>
  );
};

export default App;
